// customerReducer.js
import { types } from "../constants/types";

const initialState = {
  getMarketPrice: [],
  getMarketPriceFailed: {},
  getBTCdata: [],
  getBTCdataFailed: {},
  getETHdata: [],
  getETHdataFailed: {},
  getLTCdata:[],
  getLTCdataFailed:{},
  getBCHdata:[],
  getBCHdataFailed:{},
  getBTCFiveYearsData: [],
  getBTCFiveYearsDataFailed: {},
  getETHFiveYearsData: [],
  getETHFiveYearsDataFailed: {},
  getLTCFiveYearsData: [],
  getLTCFiveYearsDataFailed: {},
  getBCHFiveYearsData: [],
  getBCHFiveYearsDataFailed: {},
  getCryptoMarketData: {},
  getCryptoMarketDataFailed: {},
  getCustomerData: [],
  getCustomerFailed: {},
  getAccountData: [],
  getAccountFailed: {},
  postQuoteData: {},
  postQuoteFailed: {},
  postTransferData: {},
  postTransferfailed: {},
  getWorkflowData: {},
  getWorkflowFailed: {},
  getWorkflowDataForPayment: {},
  getWorkflowForPaymentFailed: {},
  getTransferData: [],
  getTransferFailed: {},
  getDepositaddressData: [],
  getDepositaddressFailed: {},
  getDepositaddressGuidData: [],
  getDepositaddressGuidFailed: {},
  postDepositaddressData: [],
  postDepositAddressFailed: {},
  postWorkflowData: {},
  postWorkflowFailed: {},
  postWorkflowDataForPayment: {},
  postWorkflowForPaymentFailed: {},
  postExternalBankAccountData: {},
  postExternalBankAccountFailed: {},
  patchExternalBankAccountData:{},
  patchExternalBankAccountFailed:{},
  getExternalBankAccountListData: {},
  getExternalBankAccountListFailed: {},
  deleteExternalBankAccountData: {},
  deleteExternalBankAccountFailed: {},
  getExternalBankWalletAccountData: {},
  getExternalBankWalletAccountFailed: {},
  deleteExternalBankWalletAccountData: {},
  deleteExternalBankWalletAccountFailed: {},
  postExternalBankWalletData: {},
  postExternalBankWalletFailed: {},
  postTradesData: {},
  postTradesFailed: {},
  postIdentityVerifyData: {},
  postIdentityVerifyFailed: {},
  getIdentityVerifyData: {},
  getIdentityVerifyFailed: {},
  postIdentitySinVerifyData: {},
  postIdentitySinVerifyFailed: {},
  getIdentitySinVerifyData: {},
  getIdentitySinVerifyFailed: {},
  commonErrorMessage: '',
  commonSuccessMessage: '',
  getTradesData: {},
  getTradesFailed: {}, 
  getIdentityVerifyListData: {},
  getIdentityVerifyListFailed: {},
  pbGetSingleExternalBankAccountData: {},
  pbGetSingleExternalBankAccountFailed: {},
  pbPostExternalBankAccountData: {},
  pbPostExternalBankAccountFailed: {},
  postContactFormData: {},
  postContactFormDataFailed: {}
};

export function customerReducer(state = initialState, action) {
  switch (action.type) {
    case types.GET_IDENTITY_VERIFICATION_LIST_SUCCESS:
      return {
        ...state,
        getIdentityVerifyListData: action.payload,
      };
    case types.GET_IDENTITY_VERIFICATION_LIST_FAILED:
      return {
        ...state,
        getIdentityVerifyListData: {},
        getIdentityVerifyListFailed: action.payload,
      };

    case types.GET_MARKET_PRICE_SUCCESS:
      return {
        ...state,
        getMarketPrice: action.payload,
      };
    case types.GET_MARKET_PRICE_FAILED:
      return {
        ...state,
        getMarketPriceFailed: action.payload,
      };
    case types.GET_CUSTOMER_SUCCESS:
      return {
        ...state,
        getCustomerData: action.payload,
      };
    case types.GET_CUSTOMER_FAILED:
      return {
        ...state,
        getCustomerData: [],
        getCustomerFailed: action.payload,
      };
    case types.GET_CUSTOMER_ACCOUNT_DETAILS_SUCCESS:
      return {
        ...state,
        getAccountData: action.payload,
      };
    case types.GET_CUSTOMER_ACCOUNT_DETAILS_FAILED:
      return {
        ...state,
        getAccountData: [],
        getAccountFailed: action.payload,
      };

    case types.POST_QUOTE_SUCCESS:
      return {
        ...state,
        postQuoteData: action.payload,
      };
    case types.POST_QUOTE_FAILED:
      return {
        ...state,
        postQuoteData: {},
        postQuoteFailed: action.payload,
      };
    case types.POST_IDENTITY_VERIFICATION_SIN_SUCCESS:
      return {
        ...state,
        postIdentitySinVerifyData: action.payload,
      };
    case types.POST_IDENTITY_VERIFICATION_SIN_FAILED:
      return {
        ...state,
        postIdentitySinVerifyData: {},
        postIdentitySinVerifyFailed: action.payload,
      };
    case types.GET_IDENTITY_VERIFICATION_SIN_SUCCESS:
      return {
        ...state,
        getIdentitySinVerifyData: action.payload,
      };
    case types.GET_IDENTITY_VERIFICATION_SIN_FAILED:
      return {
        ...state,
        getIdentitySinVerifyData: {},
        getIdentitySinVerifyFailed: action.payload,
      };
    case types.POST_IDENTITY_VERIFICATION_SUCCESS:
      return {
        ...state,
        postIdentityVerifyData: action.payload,
      };
    case types.POST_IDENTITY_VERIFICATION_FAILED:
      return {
        ...state,
        postIdentityVerifyData: {},
        postIdentityVerifyFailed: action.payload,
      };
    case types.GET_IDENTITY_VERIFICATION_SUCCESS:
      return {
        ...state,
        getIdentityVerifyData: action.payload,
      };
    case types.GET_IDENTITY_VERIFICATION_FAILED:
      return {
        ...state,
        getIdentityVerifyData: {},
        getIdentityVerifyFailed: action.payload,
      };
    case types.POST_TRANSFER_SUCCESS:
      return {
        ...state,
        postTransferData: action.payload,
      };
    case types.POST_TRANSFER_FAILED:
      return {
        ...state,
        postTransferData: {},
        postTransferfailed: action.payload,
      };

    case types.GET_BTC_PRICE_SUCCESS:
      return {
        ...state,
        getBTCdata: action.payload,
      };
    case types.GET_BTC_PRICE_FAILED:
      return {
        ...state,
        getBTCdata: [],
        getBTCdataFailed: action.payload,
      };
    case types.GET_ETH_PRICE_SUCCESS:
      return {
        ...state,
        getETHdata: action.payload,
      };
    case types.GET_ETH_PRICE_FAILED:
      return {
        ...state,
        getETHdata: [],
        getETHdataFailed: action.payload,
      };
    case types.GET_LTC_PRICE_SUCCESS:
      return {
        ...state,
        getLTCdata: action.payload,
      };
    case types.GET_LTC_PRICE_FAILED:
      return {
        ...state,
        getLTCdata: [],
        getLTCdataFailed: action.payload,
      };
    case types.GET_BCH_PRICE_SUCCESS:
      return {
        ...state,
        getBCHdata: action.payload,
      };
    case types.GET_BCH_PRICE_FAILED:
      return {
        ...state,
        getBCHdata: [],
        getBCHdataFailed: action.payload,
      };
    case types.GET_BTC_PRICE_FOR_FIVE_YEARS_SUCCESS:
      return {
        ...state,
        getBTCFiveYearsData: action.payload,
      };
    case types.GET_BTC_PRICE_FOR_FIVE_YEARS_FAILED:
      return {
        ...state,
        getBTCFiveYearsData: [],
        getBTCFiveYearsDataFailed: action.payload,
      };
    case types.GET_ETH_PRICE_FOR_FIVE_YEARS_SUCCESS:
      return {
        ...state,
        getETHFiveYearsData: action.payload,
      };
    case types.GET_ETH_PRICE_FOR_FIVE_YEARS_FAILED:
      return {
        ...state,
        getETHFiveYearsData: [],
        getETHFiveYearsDataFailed: action.payload,
      };
    case types.GET_LTC_PRICE_FOR_FIVE_YEARS_SUCCESS:
      return {
        ...state,
        getLTCFiveYearsData: action.payload,
      };
    case types.GET_LTC_PRICE_FOR_FIVE_YEARS_FAILED:
      return {
        ...state,
        getLTCFiveYearsData: [],
        getLTCFiveYearsDataFailed: action.payload,
      };
    case types.GET_BCH_PRICE_FOR_FIVE_YEARS_SUCCESS:
      return {
        ...state,
        getBCHFiveYearsData: action.payload,
      };
    case types.GET_BCH_PRICE_FOR_FIVE_YEARS_FAILED:
      return {
        ...state,
        getBCHFiveYearsData: [],
        getBCHFiveYearsDataFailed: action.payload,
      };
    case types.GET_XRP_PRICE_FOR_FIVE_YEARS_SUCCESS:
      return {
        ...state,
        getXRPFiveYearsData: action.payload,
      };
    case types.GET_XRP_PRICE_FOR_FIVE_YEARS_FAILED:
      return {
        ...state,
        getXRPFiveYearsData: [],
        getXRPFiveYearsDataFailed: action.payload,
      };
    case types.GET_CRYPTO_MARKET_DATA_SUCCESS:
      return {
        ...state,
        getCryptoMarketData: action.payload,
      };
    case types.GET_CRYPTO_MARKET_DATA_FAILED:
      return {
        ...state,
        getCryptoMarketData: [],
        getCryptoMarketDataFailed: action.payload,
      };

    case types.COMMON_ERROR_MESSAGE:
      return {
        ...state,
        commonErrorMessage: action.payload,
      };
    case types.COMMON_SUCCESS_MESSAGE:
      return {
        ...state,
        commonSuccessMessage: action.payload,
      };
    case types.GET_WORKFLOW_SUCCESS:
      return {
        ...state,
        getWorkflowData: action.payload,
      };
    case types.GET_WORKFLOW_FAILED:
      return {
        ...state,
        getWorkflowData: {},
        getWorkflowFailed: action.payload,
      };
    case types.GET_WORK_FLOW_PAYMENT_SUCCESS:
      return {
        ...state,
        getWorkflowDataForPayment: action.payload,
      };
    case types.GET_WORK_FLOW_PAYMENT_FAILED:
      return {
        ...state,
        getWorkflowDataForPayment: {},
        getWorkflowForPaymentFailed: action.payload,
      };
    case types.GET_DEPOSITADDRESS_SUCCESS:
      return {
        ...state,
        getDepositaddressData: action.payload,
      };
    case types.GET_DEPOSITADDRESS_FAILED:
      return {
        ...state,
        getDepositaddressFailed: action.payload,
      };
    case types.GET_DEPOSITADDRESS_GUID_SUCCESS:
      return {
        ...state,
        getDepositaddressGuidData: action.payload,
      };
    case types.GET_DEPOSITADDRESS_GUID_FAILED:
      return {
        ...state,
        getDepositaddressGuidFailed: action.payload,
      };
    case types.POST_DEPOSITADDRESS_SUCCESS:
      return {
        ...state,
        postDepositaddressData: action.payload,
      };
    case types.POST_DEPOSITADDRESS_FAILED:
      return {
        ...state,
        postDepositAddressFailed: action.payload,
      };
    case types.GET_TRANSFERS_SUCCESS:
      return {
        ...state,
        getTransferData: action.payload,
      };
    case types.GET_TRANSFERS_FAILED:
      return {
        ...state,
        getTransferData: [],
        getTransferFailed: action.payload,
      };
    case types.GET_TRADES_SUCCESS:
      return {
        ...state,
        getTradesData: action.payload,
      };
    case types.GET_TRADES_FAILED:
      return {
        ...state,
        getTradesData: [],
        getTradesFailed: action.payload,
      };
    case types.POST_WORKFLOWS_SUCCESS:
      return {
        ...state,
        postWorkflowData: action.payload,
      };
    case types.POST_WORKFLOWS_FAILED:
      return {
        ...state,
        postWorkflowData: {},
        postWorkflowFailed: action.payload,
      };
    case types.POST_WORK_FLOW_PAYMENT_SUCCESS:
      return {
        ...state,
        postWorkflowDataForPayment: action.payload,
      };
    case types.POST_WORK_FLOW_PAYMENT_FAILED:
      return {
        ...state,
        postWorkflowDataForPayment: {},
        postWorkflowForPaymentFailed: action.payload,
      };
    case types.POST_EXTERNAL_BANK_ACCCOUNT_SUCCESS:
      return {
        ...state,
        postExternalBankAccountData: action.payload,
      };
    case types.POST_EXTERNAL_BANK_ACCCOUNT_FAILED:
      return {
        ...state,
        postExternalBankAccountData: {},
        postExternalBankAccountFailed: action.payload,
      };
    case types.PATCH_EXTERNAL_BANK_ACCCOUNT_SUCCESS:
      return {
        ...state,
        patchExternalBankAccountData: action.payload,
      };
    case types.PATCH_EXTERNAL_BANK_ACCCOUNT_FAILED:
      return {
        ...state,
        patchExternalBankAccountData: {},
        patchExternalBankAccountFailed: action.payload,
      };
    case types.POST_EXTERNAL_BANK_ACCCOUNT_WALLET_SUCCESS:
      return {
        ...state,
        postExternalBankWalletData: action.payload,
      };
    case types.POST_EXTERNAL_BANK_ACCCOUNT_WALLET_FAILED:
      return {
        ...state,
        postExternalBankWalletData: {},
        postExternalBankWalletFailed: action.payload,
      };
    case types.GET_EXTERNAL_BANK_ACCCOUNT_LIST_SUCCESS:
      return {
        ...state,
        getExternalBankAccountListData: action.payload,
      };
    case types.GET_EXTERNAL_BANK_ACCCOUNT_LIST_FAILED:
      return {
        ...state,
        getExternalBankAccountListData: {},
        getExternalBankAccountListFailed: action.payload,
      };
    case types.GET_SINGLE_EXTERNAL_BANK_ACCCOUNT_SUCCESS:
      return {
        ...state,
        getSingleExternalBankAccountData: action.payload,
      };
    case types.GET_SINGLE_EXTERNAL_BANK_ACCCOUNT_FAILED:
      return {
        ...state,
        getSingleExternalBankAccountData: {},
        getSingleExternalBankAccountFailed: action.payload,
      };
    case types.GET_EXTERNAL_BANK_ACCCOUNT_WALLET_LIST_SUCCESS:
      return {
        ...state,
        getExternalBankWalletAccountData: action.payload,
      };
    case types.PB_GET_SINGLE_EXTERNAL_BANK_ACCCOUNT_SUCCESS:
      return {
        ...state,
        pbGetSingleExternalBankAccountData: action.payload,
      };
    case types.PB_GET_SINGLE_EXTERNAL_BANK_ACCCOUNT_FAILED:
      return {
        ...state,
        pbGetSingleExternalBankAccountData: {},
        pbGetSingleExternalBankAccountFailed: action.payload,
      };
    case types.PB_POST_EXTERNAL_BANK_ACCCOUNT_SUCCESS:
      return {
        ...state,
        pbPostExternalBankAccountData: action.payload,
      };
    case types.PB_POST_EXTERNAL_BANK_ACCCOUNT_FAILED:
      return {
        ...state,
        pbPostExternalBankAccountData: {},
        pbPostExternalBankAccountFailed: action.payload,
      };
    case types.GET_EXTERNAL_BANK_ACCCOUNT_WALLET_LIST_FAILED:
      return {
        ...state,
        getExternalBankWalletAccountData: {},
        getExternalBankWalletAccountFailed: action.payload,
      };
    case types.DELETE_EXTERNAL_BANK_ACCCOUNT_SUCCESS:
      return {
        ...state,
        deleteExternalBankAccountData: action.payload,
      };
    case types.DELETE_EXTERNAL_BANK_ACCCOUNT_FAILED:
      return {
        ...state,
        deleteExternalBankAccountData: {},
        deleteExternalBankAccountFailed: action.payload,
      };
    case types.DELETE_EXTERNAL_BANK_ACCCOUNT_WALLET_SUCCESS:
      return {
        ...state,
        deleteExternalBankWalletAccountData: action.payload,
      };
    case types.DELETE_EXTERNAL_BANK_ACCCOUNT_WALLET_FAILED:
      return {
        ...state,
        deleteExternalBankWalletAccountData: {},
        deleteExternalBankWalletAccountFailed: action.payload,
      };
    case types.POST_TRADES_SUCCESS:
      return {
        ...state,
        postTradesData: action.payload,
      };
    case types.POST_TRADES_FAILED:
      return {
        ...state,
        postTradesData: {},
        postTradesFailed: action.payload,
      };
    case types.GENERATE_CUSTOMER_ACCOUNT_SUCCESS:
      return {
        ...state,
        customerAccountData: action.payload,
      };
    case types.GENERATE_CUSTOMER_ACCOUNT_FAILED:
      return {
        ...state,
        customerAccountData: {},
        customerAccountDataFailed: action.payload,
      };
    case types.POST_CONTACT_FORM_DATA_SUCCESS:
      return {
        ...state,
        postContactFormData: action.payload,
      };
    case types.POST_CONTACT_FORM_DATA_FAILED:
      return {
        ...state,
        postContactFormData: {},
        postContactFormDataFailed: action.payload,
      };
    default:
      return state;
  }
}
